import React from 'react'
import './styles/lock.css'

export default function Lock(props) {

  return (
    <div className='lock'>
        
    </div>
    
  )

}

